<template>
    <div style="height:100%;width:100" :style="[{background: background}]">
		<slot :items="pageData" :extra="extra" />

        <div v-if="pagination && pageInfo.total > 0" class="pagination">
			<slot v-if="$slots['pagination']"/>
            <el-pagination v-else :current-page="pageInfo.page" :page-size="pageInfo.size" :total="pageInfo.total" :page-sizes="[5,10,15,20,30,50,100,200,300,400]" layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handlePageChange" />
        </div>
    </div>
</template>
<script>
export default {
    name: "pageList",
    props: {
		api: {
			type: String,
			default: ''
		},
		extra: null,
		autoreset:{
			type: Boolean,
			default: true
		},
		resetby: {
			type: String,
			default: 'api'
		},
		search: {
			type: Object,
			default () {
				return {}
			}
		},
		background: {
			type: String,
			default: 'white'
		},
		pagination: {
			type: Boolean,
			default: true
		},
    },
    data(){
        return {
			loading: null,
			pageSearch:{},
            pageData:[],
            pageInfo:{
            	page:1,
            	size:20,
				total:0,
            },
        }
    },
    mounted() {
		this.pageSearch    = this.search
		this.pageInfo.page = this.search.page || 1
		this.pageInfo.size = this.search.size || 20
		if( this.autoreset ){
			this.reset()
		}
    },
    methods: {
    	reset(){
    		this.pageData      = [];
    		this.pageInfo.page = this.pageSearch.page || 1
			this.pageInfo.size = this.pageSearch.size || 20
    		this.loadData();
    	},
    
    	loadData() {
			
    		if( (!this.loading || this.loading.visible==false) && this.pageInfo.page > 0 ){
    			this.loading = this.$loading({lock: true,text: '加载中',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
				this.$get(this.api,Object.assign(this.pageInfo,this.pageSearch)).then(res=>{
					if( this.pageData.length == 0 && res.data.length > 0 ){
						this.$emit('ready',res.data);
					}
    				this.loading.close(),this.pageData = res.data,this.pageInfo.total = res.extra.pageInfo.count,this.pageInfo.page = res.extra.pageInfo.page;
    			}).catch(err=>{
    				this.loading.close(),this.pageInfo.total = 0,this.pageInfo.page = 0;
    			})
    		}
    	},
		
		handleSizeChange(val) {
			this.pageInfo.size = val,this.loadData();
		},
		handlePageChange(val) {
			this.pageInfo.page = val,this.loadData();
		}
    },
    watch:{
    	api( val,old ){
    		if( this.autoreset && val != old && this.reset=='api' ){
    			this.loading = false,this.reset();
    		}
		},
		search(val){
			this.pageSearch = val
			if( this.autoreset && this.reset=='search' ){
    			this.loading = false,this.reset();
    		}
		}
    }
}
</script>
<style lang="scss" scoped>
.pagination{
	text-align: center;
}
</style>